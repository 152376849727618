import { useState } from 'react';
import logo from '../../logos.png';
import TawkMessengerComp from '../messenger/TawkMessenger';
import { Link } from 'react-router-dom';
import TrueFooterComponent from '../TrueFooter';
const FooterComponent = () =>{
    const [isVisible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  window.addEventListener('scroll', toggleVisible);
    return(
        <div className='footer'>
            <div>
            <div className="qtFooter" >
                
                {
                    isVisible && 
                    <div onClick={scrollToTop}>
                    <span className="scrollTops">Scroll Up</span>
                    <div className="clearfix"></div>
                    </div>
                    
                }
                
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 footerLogos">
                            <div className='footerLogo'>
                            <Link to="/"><img src={logo} width="100%" alt="Qtree Tech"/></Link>
                        </div>
                        </div>

                        <div className="col-md-3">

                                <div className="qtFooterContent">
                                    <div className='qtFooterHead'>
                                        <h3>Explore Cadbench</h3>
                                    </div>
                                    <div className='qtFooterMain'>
                                        <ul>
                                            <li><Link to="/courses">Our Courses</Link></li>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/contact">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                
                        </div>

                        <div className="col-md-3">
                        <div className="qtFooterContent">
                                    <div className='qtFooterHead'>
                                        <h3>Quick Links</h3>
                                    </div>
                                    <div className='qtFooterMain'>
                                    <ul>
                                            <li><Link to="/termsconditions">Terms & Conditions</Link></li>
                                            <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                                            <li><Link to="/cancelrefund">Cancellation & Refunds</Link></li>
                                            <li><Link to="/shippingdelivery">Shipping & Delivery</Link></li>
                                        </ul>
                                    </div>
                                </div>
                        </div>

                        <div className='col-md-3'>
                        <div className="qtFooterContent">
                                    <div className='qtFooterHead'>
                                        <h3>Follow us on</h3>
                                    </div>
                                    <div className='qtFooterMain'>
                                        <ul>
                                            {/* <li><a href="https://www.facebook.com/people/Cadbench/61554921329711/" target='_blank' rel="noreferrer"><i class="fa fa-facebook" aria-hidden="true"></i>&nbsp;&nbsp;Facebook</a></li>
                                        
                                            <li><a href="https://twitter.com/CubikCadd" target='_blank' rel="noreferrer"><span style={{fontWeight: "bold"}}>X</span>&nbsp;Twitter</a></li>
                                            <li><a href="https://www.linkedin.com/company/cadbench" target='_blank' rel="noreferrer"><i class="fa fa-linkedin" aria-hidden="true"></i>&nbsp;&nbsp;linkedin</a></li>
                                            <li><a href="https://www.instagram.com/cad_bench/" target='_blank' rel="noreferrer"><i class="fa fa-instagram" aria-hidden="true"></i>&nbsp;&nbsp;Instagram</a></li> */}
                                            <li><a href="https://www.facebook.com/people/Cadbench/61554921329711/" target='_blank' rel="noreferrer"><i class="fa fa-facebook" aria-hidden="true"></i>&nbsp;&nbsp;Facebook</a></li>
                                
                                        <li><a href="https://x.com/cadbench" target='_blank' rel="noreferrer"><span style={{fontWeight: "bold"}}>X</span>&nbsp;Twitter</a></li>
                                        <li><a href="https://www.linkedin.com/company/cadbench" target='_blank' rel="noreferrer"><i class="fa fa-linkedin" aria-hidden="true"></i>&nbsp;&nbsp;linkedin</a></li>
                                        <li><a href="https://www.instagram.com/cad_bench/" target='_blank' rel="noreferrer"><i class="fa fa-instagram" aria-hidden="true"></i>&nbsp;&nbsp;Instagram</a></li>
                                        <li><a href="https://www.youtube.com/channel/UC291Hjbu3EaZd4Tz-3Wu-hg" target='_blank' rel="noreferrer"><i class="fa fa-youtube-play" aria-hidden="true"></i>&nbsp;&nbsp;Youtube</a></li>
                                        </ul>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className='qtTrueFooter'>
                <div className='container'>
                    &copy; {(new Date().getFullYear())} Cubiksoft Technologies Private Limited
                </div>
            </div>
            </div>
            {/* <ScrollComponent/> */}
            <TawkMessengerComp/>
            {/* <TrueFooterComponent/> */}
            <TrueFooterComponent/> 
            </div>
        </div>
    )
}

export default FooterComponent;